import React from "react";
import styled from "styled-components/macro";

type PropsT = {
  messages: string[] | React.ReactNode[];
  border?: boolean;
}
const ErrorsMessages = (props:PropsT) => {
  return(
    <CenterContainer>
      <ErrorMessages border={props.border}>
        {props.messages.map((item, index) =>
          <Message key={`error_message_${index}`}>{item}</Message>
        )}
      </ErrorMessages>
    </CenterContainer>
  )
}

export default ErrorsMessages;

const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorMessages = styled.div<{border: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  padding: 15px;
  border: ${(p) => p.border ? 1: 0}px solid ${(p) => p.theme.palette.accentSecondary};
`;
ErrorsMessages.defaultProps = {
  border: true
}

const Message = styled.div`
  display: flex;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

export const MessageItem = styled.div<{color?: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: ${(p) => p?.color === 'accent' ? p.theme.palette.accentSecondary : p.theme.palette.textSecondary};
`;