import { auth3RnDClient, authCoreClient } from './base';

export const ApiInfo = {
  get: (token) => auth3RnDClient(token).get('/info/'),
};

// AsedaSciences Core Services
export const UserRegistration = {
  get: (token, config) => authCoreClient(token).get('/user/', config),
  create: (token, data) => authCoreClient(token).post('/user/create', data),
  update: (token, data) => authCoreClient(token).post('/user/update', data),
  delete: (token, data) => authCoreClient(token).post('/user/delete', data),
};

// AsedaSciences Core Services
export const BugReports = {
  get: (token, config) => authCoreClient(token).get('/bugs/', config),
  submit: (token, data) => authCoreClient(token).post('/bugs/submit', data),
  update: (token, data) => authCoreClient(token).put('/bugs/update', data),
  delete: (token, id) => authCoreClient(token).post('/bugs/delete', id),
};

// AsedaSciences Request Services

