import React from 'react';
import styled from 'styled-components/macro';
import { Tooltip, ButtonBase } from '@mui/material';
import NotificationCount from "./NotificationCount";

interface MenuButtonPropsT {
  text: string,
  icon: React.ReactNode,
  notificationCount?: number,
  onClick?: ()=>void,
  isActive?: boolean,
  width?: number,
  height?: number,
  margin?: string,
  spacing?: string,
  tooltipPlacement?: 'right' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | 'top',
}

const MenuButton = (props:MenuButtonPropsT) => {
  return (
    <MenuButtonContainer margin={props.margin}>
      { props?.notificationCount ? <NotificationCount count={props.notificationCount} /> : null }
      <Tooltip
        title={props.text}
        arrow
        placement = {props.tooltipPlacement}
      >
        <IconButton
          onClick={props.onClick}
          className={props.isActive ? 'active' : ''}
        >
          { props.icon }
        </IconButton>
      </Tooltip>
    </MenuButtonContainer>
  );
}
export default React.memo(MenuButton);

MenuButton.defaultProps = {
  isActive: false,
  margin: "4px 1px",
  tooltipPlacement: "right"
}

const MenuButtonContainer = styled.div<{margin: string}>`
  position: relative;
  margin: ${(p) => p.margin};
  border-radius: 4px;
  padding: 2px;
  :hover {
    background-color: ${(p) => p.theme.palette.backgroundQuaternary };
  }
`;

const IconButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  
  border-radius: 10px;
  filter: grayscale(1.0) brightness(${(p) => p.theme.menu.inActive.brightness}) 
        contrast(${(p) => p.theme.menu.inActive.contrast});
  :hover {
    filter: grayscale(0.0) brightness(${(p) => p.theme.menu.active.brightness}) 
        contrast(${(p) => p.theme.menu.active.contrast});
  }
  &:active {
    filter: grayscale(0.0) brightness(${(p) => p.theme.menu.active.brightness}) 
        contrast(${(p) => p.theme.menu.active.contrast});
  };
`;
