import {AxiosRequestConfig, AxiosResponse} from "axios";
import useCognito from "@as_core/account/useCognito";
import {authServicesClient} from "@utils/api/base";

// the Request Object Type
export type RequestT = {
  id: string;
  name: string;
  user_id: string;
  org_id: string;
  created_at: string;
  updated_at: string;
  request_type: string;
  cat_code: string;
  prod_name: string;
  quantity: number;
  unit_value: string;
  assigned_to: string;
  status: string;
  compounds: number;
  itemType?: string;
  userEmail?: string;
}

// the Request Update Object Type
export type RequestUpdateT = {
  name?: string;
  user_id?: string;
  org_id?: string;
  request_type?: string;
  cat_code?: string;
  prod_name?: string;
  quantity?: number;
  unit_value?: string;
  assigned_to?: string;
  status?: string;
}

export enum RequestStatus {
  OPEN = "Open",
  SHIPPED = "Shipped",
  RECEIVED = "Received",
  RUNNING = "Running",
  LOADING = "Loading",
  QC_CHECK = "QC Check",
  DELIVERED = "Delivered",
  // ARCHIVED = "Archived"
}

const ActionToStatus: {[key: string]: RequestStatus} = {
  'open': RequestStatus.OPEN,
  'shipped': RequestStatus.SHIPPED,
  'received': RequestStatus.RECEIVED,
  'running': RequestStatus.RUNNING,
  'processing': RequestStatus.LOADING,
  'qc': RequestStatus.QC_CHECK,
  'complete': RequestStatus.DELIVERED
}

// the api settings
export const Requests = {
  all: (token: string, config: AxiosRequestConfig) => authServicesClient(token).get('/requests', config),
  get: (token: string, orderId: string) => authServicesClient(token).get(`/requests/${orderId}`),
  byId: (token: string, query: string) => authServicesClient(token).get(`/requests/list${query}`),
  byStatus: (token: string, status: string) => authServicesClient(token).get(`/requests?status=${status}`),
  byOrder: (token: string, orderId: string) => authServicesClient(token).get(`/requests/order_id/${orderId}`),
  forUser: (token:string, userId: string) => authServicesClient(token).get(`/requests/user/${userId}`),
  create: (token: string, config: AxiosRequestConfig) => authServicesClient(token).post('/requests', config),
  update: (token: string, requestId: string, config: AxiosRequestConfig) => authServicesClient(token).patch(`/requests/${requestId}`, config),
};


function actionToStatus(action:string) {
  if (Object.hasOwn(ActionToStatus, action)) return ActionToStatus[action];
  console.error('Unknown API status for action: ', action);
  return action;
}

const useRequests = () => {
  const { getToken } = useCognito();

  // eslint-disable-next-line
  function processAxiosResponse (method: string, response: AxiosResponse<any>)  {
    if (response.status !== 200) {
      console.error(`API error in ${method}: `, response.statusText);
      return null;
    }
    return response.data;
  }

  const getRequests = async (params: { [key: string]: string }): Promise<RequestT[]> => {
    const config: AxiosRequestConfig = { params };
    let resp: AxiosResponse<RequestT[]> = await Requests.all(getToken(), config);
    return processAxiosResponse('getRequest', resp);
  };

  const getRequestsByIds = async (ids: number[]): Promise<RequestT[]> => {
    if (ids.length === 0) return [];
    const id_query = ids.map((id, index) => ((index === 0? '': '&') + `ids=${id}`));
    const query = '?'.concat(...id_query);
    let resp: AxiosResponse<RequestT[]> = await Requests.byId(getToken(), query);
    return processAxiosResponse('getRequestsByIds', resp);
  };

  const getRequestsByStatus = async (status:string): Promise<RequestT[]> => {
    const token = getToken();
    let resp: AxiosResponse<RequestT[]> = await Requests.byStatus(token, actionToStatus(status));
    return processAxiosResponse('getRequestsByStatus', resp);
  };

  const getRequestsByOrder = async (orderId:string): Promise<RequestT[]> => {
    const token = getToken();
    console.log('getRequestsByOrder', orderId);
    let resp: AxiosResponse<RequestT[]> = await Requests.byOrder(token, orderId);
    return processAxiosResponse('getRequestsByOrder', resp);
  };

  const getUserRequests = async (userId: string): Promise<Array<RequestT>> => {
    let resp: AxiosResponse<RequestT[]> = await Requests.forUser(getToken(), userId);
    return processAxiosResponse('getUserRequests', resp);
  };

  const createRequest = async (params: { [key:string]: string | number }): Promise<RequestT> => {
    const config: AxiosRequestConfig = params;
    let resp: AxiosResponse<RequestT> = await Requests.create(getToken(), config);
    return processAxiosResponse('createRequests', resp);
  };

  const updateRequest = async (requestId: string, updates: {[key: string]: string | number}): Promise<RequestT> => {
    const config: AxiosRequestConfig = updates;
    let resp: AxiosResponse<RequestT> = await Requests.update(getToken(), requestId, config);
    return processAxiosResponse('updateRequest', resp);
  };

  return {
    getRequests,
    getRequestsByIds,
    getRequestsByStatus,
    getRequestsByOrder,
    getUserRequests,
    createRequest,
    updateRequest
  };
};

export default useRequests;
