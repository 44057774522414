import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import FulfillmentCompleteIcon from "@as_core/icons/fulfillmentComplete.icon";
import FulfillmentNewIcon from "@as_core/icons/fulfillmentNew.icon";
import FulfillmentShippedIcon from "@as_core/icons/fulfillmentShipped.icon";
import FulfillmentReceivedIcon from "@as_core/icons/fulfillmentReceived.icon";
import FulfillmentRunningIcon from "@as_core/icons/fulfillmentRunning.icon";
import FulfillmentProcessingIcon from "@as_core/icons/fulfillmentProcessing.icon";
import FulfillmentQCIcon from "@as_core/icons/fulfillmentQC.icon";

export const ordersMenuItems: MenuItemsT[] = [
  {
    id: 'reqOpen',
    name: 'New Requests',
    pathname: '/requests/open',
    icon: <FulfillmentNewIcon />
  },
  {
    id: 'reqShipped',
    name: 'Customer Shipped',
    pathname: '/requests/shipped',
    icon: <FulfillmentShippedIcon />
  },
  {
    id: 'reqReceived',
    name: 'Compounds Received',
    pathname: '/requests/received',
    icon: <FulfillmentReceivedIcon />
  },
  {
    id: 'reqAssayRunning',
    name: 'Assay Running',
    pathname: '/requests/running',
    icon: <FulfillmentRunningIcon />
  },
  {
    id: 'reqProcessingData',
    name: 'Processing Data',
    pathname: '/requests/processing',
    icon: <FulfillmentProcessingIcon />
  },
  {
    id: 'reqDataQC',
    name: 'Data QC',
    pathname: '/requests/qc',
    icon: <FulfillmentQCIcon />
  },
  {
    id: 'reqComplete',
    name: 'Complete',
    pathname: '/requests/complete',
    icon: <FulfillmentCompleteIcon />
  },
];

const debug = false;
export const getRequestsMenus = (subscription: string) => {
  if (debug) console.log('getMenuAccount | subscription', subscription);
  return ordersMenuItems;
}


