import React from "react";
import { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface IconPropsI {
  size?: number;
}

const IconSwitch = ({ size = 24 }: IconPropsI) => {
  const [style] = useContext(StyleContext);
  const color = style.name === 'dark' ? 'white' : 'black';
  const location = style.name === 'dark' ? '9.5' : '19.5';
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='4.5'
        y='7'
        width='20'
        height='10'
        rx='5'
        fill={color}
        fillOpacity='0.2'
      />
      <circle cx={location} cy='12' r='5' fill={color} />
    </svg>
  );
};

export default IconSwitch;
