import axios from 'axios';

console.log('base.ts | process.env', process.env);

// interfaces
export interface ResponseT<T> {
  errors: string[];
  limit?: number;
  total?: number;
  size?: number;
  start_after?: number;
  continues?: boolean;
  data?: T;
}

/* Set up the apiClient endpoints set depending on environment parameter REACT_APP_HOST_TYPE */

export const api3RnDClient = axios.create({
  baseURL: process.env.REACT_APP_3RND_URI,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const auth3RnDClient = (token:string) => {
  api3RnDClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api3RnDClient;
};


/* Set up the apiCoreClient endpoints set depending on environment parameter REACT_APP_API_CORE */
export const apiCoreClient = axios.create({
  baseURL: process.env.REACT_APP_CORE_URI,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const authCoreClient = (token:string) => {
  apiCoreClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return apiCoreClient;
};

// KEY 3RnD compound service api
export const apiServicesClient =  axios.create({
  baseURL: process.env.REACT_APP_SERVICES_URI,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authServicesClient = (token) => {
  apiServicesClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return apiServicesClient;
};
