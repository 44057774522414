import React from "react";
import SciScore from '@components/icons/IconSciscore';
import Icon3RnD from '@components/icons/Icon3RnD';
import OurBiologyIcon from '@components/icons/ourBiology.icon';
import {HomeTileCardT} from "@as_core/tiles/HomePageTiles";

export const asedaCards: HomeTileCardT[] = [
  {
    title: 'Aseda Sciences Website',
    newTab: true,
    icon: (
      <img
        src={'/img/AsedaSciences_logo.svg'}
        alt={'AsedaSciences'}
        width={64}
      />
    ),
    link: 'https://asedasciences.com',
    description: 'Look at the main AsedaSciences website for more information',
  },
  {
    title: '3RnD Application',
    newTab: true,
    icon: <Icon3RnD size={64} />,
    link: 'https://app-prd.thewarriors3.com',
    description: 'High-performance data analysis and visualization',
  },
  {
    id: 'AS_SciScore',
    title: 'SciScore',
    newTab: true,
    icon: <SciScore size={64} />,
    link: 'https://sciscore.asedasciences.com',
    description: 'Score methods section of a manuscript before submission',
  },
];

export const toolsCards: HomeTileCardT[] = [
  {
    title: 'Request Handling',
    newTab: false,
    icon: <OurBiologyIcon size={64} />,
    link: '/requests/open',
    description: 'Assay/Request Handling for AsedaSciences Partners',
  }
];
