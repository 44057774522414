import {UserT} from "@as_core/contexts/UserContext";
import {RequestT} from "./useRequests";

export const VENDOR_MASK:{[key: string]: string} = {
  'AS_V_0001': 'AsedaSciences',
  'AS_V_0002': 'Tanguay Laboratories',
  'AS_V_0003': 'Klara Valko',
  'AS_V_0004': 'IndigoBridge',
}

export const VENDOR_EMAIL:{[key: string]: string[]} = {
  'dan.robertson@asedasciences.com': ['AS_V_0001', 'AS_V_0002', 'AS_V_0003', 'AS_V_0004'],
  'brad.calvin@asedasciences.com': ['AS_V_0001', 'AS_V_0002', 'AS_V_0003', 'AS_V_0004'],
  'klara_valko@bio-mimetic-chromatography.com': ['AS_V_0003'],
  'matthew@indigobridgelab.com': ['AS_V_0004'],
}

export type VENDOR = '' | 'AS_V_0001' | 'AS_V_0002' | 'AS_V_0003' | 'AS_V_0004';

const catalogToVendor: {[key: string]: VENDOR | VENDOR[]} = {
  'AS-SYS-ACS12' : 'AS_V_0001',
  'AS-SYS-ACS12-ACD' : 'AS_V_0001',
  'AS-SCREEN-3RND': 'AS_V_0001',
  'AS-ZBE-ACT236': 'AS_V_0002',
  'AS-ZBE-ACT236-ACD': 'AS_V_0002',
  'AS-ZBE-DTX16-LT' : 'AS_V_0002',
  'AS-ZBE-DTX16-LT-ACD' : 'AS_V_0002',
  'AS-ZBE-DTX16': 'AS_V_0002',
  'AS-ZBE-DTX16-ACD': 'AS_V_0002',
  'AS-ZBE-DTX16-CTG': 'AS_V_0002',
  'AS-CQC-SST': 'AS_V_0004',
  'AS-CQC-SST-ACD': 'AS_V_0004',
  'AS-BMVK-KVC': 'AS_V_0003',
  'AS-BMVK-BMCH': ['AS_V_0003', 'AS_V_0004'],
  'AS-BMVK-BMCH-ACD': ['AS_V_0003', 'AS_V_0004'],
  '': '',
}

const RequestTypes = { // default is 'assay'
  'consulting': ['AS-BMVK-KVC', 'AS-ZBE-DTX16-CTG'],
  'subscription': ['AS-SCREEN-3RND'],
}


const debug = false;
const useVendors= () => {

  const autoAssign = (catalogNumber: string): string => {
    if (!Object.hasOwn(catalogToVendor, catalogNumber)) {
      return '';
    }
    if (typeof (catalogToVendor[catalogNumber]) === 'string') {
      return catalogToVendor[catalogNumber] as string;
    } else {
      return '';
    }
  };

  // retrieve type
  const getRequestType = (request: RequestT): string => {
    return getRequestTypeByCatNo(request.cat_code);
  }

  const getRequestTypeByCatNo = (cat_no: string): string => {
    let requestType = 'screen';
    Object.keys(RequestTypes).forEach((k: string) => {
      if (RequestTypes[k].includes(cat_no)) requestType = k;
    })
    return requestType;
  }

  const getRequestCategory = (cat_no: string): string => {
    if (cat_no === 'AS-BMVK-KVC') {
      return "Klara Consulting"
    } else if (cat_no.includes('AS-BMVK')) {
      return "Biomimetic"
    } else if (cat_no === 'AS-ZBE-DTX16-CTG') {
      return "Zebrafish Consulting"
    } else if (cat_no.includes('AS-ZBE')) {
      return "Zebrafish"
    } else if (cat_no.includes('AS-CQC')) {
      return "QC Solubility"
    } else if (cat_no.includes('AS-SYS')) {
      return "Cell Health"
    }
  }

  const getVendorName = (vendorCode: string): string => {
    if (vendorCode === null || vendorCode === '' || !Object.hasOwn(VENDOR_MASK, vendorCode)) return vendorCode;
    return VENDOR_MASK[vendorCode];
  }

  const getVendorAuthorizationByEmail = (user: UserT): string[] => {
    const vendorEmail: string = user?.authEmail ? user.authEmail : '';
    if (vendorEmail === '' || !Object.hasOwn(VENDOR_EMAIL, vendorEmail)) return [];
    return VENDOR_EMAIL[vendorEmail];
  }

  const getVendorOptions = (catalogNumber: string): {value: string, label: string}[] => {
    const options = [];
    if (!Object.hasOwn(catalogToVendor, catalogNumber)) {
      console.error(`Error: ${catalogNumber} does not exist in catalogToVendor`);
      return options;
    }
    const vendors = catalogToVendor[catalogNumber];
    if (debug) console.log('getVendorOptions | ', vendors);
    if (typeof vendors === 'string') {
      options.push({value: vendors, label: VENDOR_MASK[vendors]});
    } else {
      vendors.forEach((vendor) => {
        options.push({value: vendor, label: VENDOR_MASK[vendor]});
      });
    }
    return options;
  }

  return {
    autoAssign,
    getRequestCategory,
    getRequestType,
    getRequestTypeByCatNo,
    getVendorName,
    getVendorAuthorizationByEmail,
    getVendorOptions
  };
};

export default useVendors;
