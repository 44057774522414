import React, {useEffect, useState} from "react";
import styled from 'styled-components/macro';
import {FlexBanner, FlexBannerGroup} from '@as_core/elements/flexStyles';
import {VENDOR_MASK} from "../hooks/useVendors";
import MenuButton from "@components/elements/MenuButton";
import BackArrowIcon from "@components/icons/backArrow.icon";
import {RequestT} from "../hooks/useRequests";

type PropsT = {
  updateRequest: (r: RequestT)=>void;
  userAuthorizations: string[],
  showBack?: boolean,
}

const ReportPageHeader = (props:PropsT) => {
  const {updateRequest, userAuthorizations, showBack = false} = props;
  const [userAuthText, setUserAuthText] = useState<string>("");
  useEffect(() => {
    const newUserAuthText = userAuthorizations.map((a) => (Object.hasOwn(VENDOR_MASK, a) ? VENDOR_MASK[a] : a)).join(', ');
    setUserAuthText('Authorizations: ' + newUserAuthText);
  }, [userAuthorizations]);

  return(
    <StyledFlexBanner height={40} border>
      <FlexBannerGroup>
          { showBack ?
            <MenuButton
              onClick={()=> updateRequest(null)}
              width={50}
              icon={<BackArrowIcon />}
              text={'Back to Requests List'}
              tooltipPlacement={'bottom'}
            />
            :
            <></>
          }
      </FlexBannerGroup>
      <MidBanner>{userAuthText}</MidBanner>
      <FlexBannerGroup></FlexBannerGroup>
    </StyledFlexBanner>
  )
}
export default ReportPageHeader;

const StyledFlexBanner = styled(FlexBanner)`
  background: ${(p) => p.theme.palette.backgroundSecondary };
  margin-bottom: 15px;
`;

const MidBanner = styled(FlexBannerGroup)`
  font-size: 12px;
  font-style: italic;
  color: ${(p) => p.theme.palette.textSecondary};
`;
