import React from "react";
import styled from 'styled-components/macro';

interface NotificationCountT {
  count?: number
}

const debug = false;
const NotificationCount = ({ count }:NotificationCountT) => {
  debug && console.log("NotificationCount | count:", count);
  return count && count > 0 ? (
    <NotificationCountBubble>{count}</NotificationCountBubble>
  ) : (
    <span />
  );
};

export default NotificationCount;

const NotificationCountBubble = styled.div`
  position: absolute;
  font-size: 11px;
  z-index: 1;
  top: -2px;
  left: -2px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.textPrimary };
  background-color:  ${(props) => props.theme.palette.accentPrimary };
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
