import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getValueByPath, handleError } from '@utils/misc';
import useCognito from '@as_core/account/useCognito';
import { RepositoriesT } from '@subApps/account/useRepos';
import { auth3RnDClient as authClient, authCoreClient } from '@utils/api/base';
import { UserT } from '@as_core/contexts/UserContext';

export type UserListItemT = {
  authEmail: string;
  authId: string;
  lastLogin: string;
  roles: string[];
  subscription: string;
};

export type UserEventsItemT = {
  _id: string;
  time: string;
  authId: string;
  authEmail?: string;
  event: string;
  event_type: string;
  message: string;
};

// Types for User API responses and related structures
export type SubscriptionT = {
  uuid: string;
  type: string;
  ownerAuthId: string;
  ownerAuthEmail: string;
  invitedUsers?: SubscriptionSharesT[];
  allowedInvites: number;
  allowedRepositories: number;
  created?: string;
  expires?: string;
  source?: string;
  sourceId?: string;
};

export type SubscriptionSharesT = {
  authId: string;
  authEmail: string;
  sharedOn: string;
};

export type AppInfoT = {
  _id?: string;
  authId?: string;
  roles?: string[];
  subscription: SubscriptionT;
  repositories: RepositoriesT;
  lastLogin?: string;
};

export interface ResponseI<T> {
  errors: string[];
  data: T;
}

// The User object with typed API methods
const User = {
  get: (
    token: string,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseI<AppInfoT>>> =>
    authClient(token).get('/user', config),

  getRepositories: (
    token: string
  ): Promise<AxiosResponse<ResponseI<RepositoriesT>>> =>
    authClient(token).get('/user/repositories'),

  getAll: (token: string): Promise<AxiosResponse<ResponseI<UserListItemT[]>>> =>
    authClient(token).get('/user/all'),

  getUserAdmin: (
    token: string,
    userId: string
  ): Promise<AxiosResponse<ResponseI<AppInfoT>>> =>
    authClient(token).get(`/user/details/${userId}`),

  getAllUserEvents: (
    token: string,
    days: string | number
  ): Promise<AxiosResponse<ResponseI<UserEventsItemT>>> =>
    authClient(token).get(`/user/events?days=${days}`),

  create: (
    token: string,
    data: { [key: string]: unknown }
  ): Promise<AxiosResponse<ResponseI<AppInfoT>>> =>
    authClient(token).post('/user/create', data),

  update: (
    token: string,
    data: { [key: string]: unknown }
  ): Promise<AxiosResponse<ResponseI<AppInfoT>>> =>
    authCoreClient(token).post('/user/update', data),

  delete: (
    token: string,
    data: { [key: string]: unknown }
  ): Promise<AxiosResponse<ResponseI<string>>> =>
    authClient(token).post('/user/delete', data),
};

// Utility functions with improved typing
export function getUserSubscriptionType(appInfo: AppInfoT) {
  return getValueByPath(appInfo.subscription, 'type', 'basic');
}

export function getMaxRepositories(user: UserT) {
  if (user.appInfo.subscription.ownerAuthId === user.authId)
    return user.appInfo.subscription.allowedRepositories;
  return getUserSubscriptionType(user.appInfo) === 'basic' ? 0 : 1;
}

export function getMaxInvites(user: UserT) {
  return user.appInfo.subscription.ownerAuthId === user.authId
    ? user.appInfo.subscription.allowedRepositories
    : 0;
}

const debug = false;

const useUserAppInfo = () => {
  const { getToken } = useCognito();

  const getUserInfo = async (
    token: string,
    params: { [key: string]: unknown }
  ): Promise<AppInfoT | null> => {
    const config: AxiosRequestConfig = { params };
    let resp;
    try {
      resp = await User.get(token, config);
      if (resp.data.errors.length > 0) {
        alert(resp.data.errors.join(', '));
        console.error('getUserInfo | Errors:', resp.data.errors);
        return null;
      }
      if (debug) console.log('getUserInfo | resp', resp);
    } catch (err) {
      alert(err);
      console.error('useUserAppInfo | getUserInfo ERRORS (err)', err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('useUserAppInfo | getUserInfo ERRORS', resp.data.errors);
    }
    return resp.data.data;
  };

  const getUserRepositories = async (): Promise<RepositoriesT | null> => {
    try {
      const resp = await User.getRepositories(getToken());
      if (resp.data.errors.length > 0) {
        alert(resp.data.errors.join(', '));
        console.error('getUserRepositories | Errors:', resp.data.errors);
        return null;
      }
      if (debug) console.log('getUserRepositories | resp', resp);
      return resp.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const getAllUsers = async (): Promise<UserListItemT[] | null> => {
    try {
      const resp = await User.getAll(getToken());
      if (resp.data.errors.length > 0) {
        alert(resp.data.errors.join(', '));
        console.error('getAllUsers | Errors:', resp.data.errors);
        return null;
      }
      if (debug) console.log('getAllUsers | resp', resp);
      return resp.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const getUserByAdmin = async (userId: string): Promise<AppInfoT | null> => {
    try {
      const resp = await User.getUserAdmin(getToken(), userId);
      if (resp.data.errors.length > 0) {
        alert(resp.data.errors.join(', '));
        console.error('getUserByAdmin | Errors:', resp.data.errors);
        return null;
      }
      if (debug) console.log('getUserByAdmin | resp', resp);
      return resp.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const getAllUserEvents = async (
    days: number | string
  ): Promise<UserEventsItemT[] | null> => {
    try {
      const resp = await User.getAllUserEvents(getToken(), days);
      if (resp.data.errors.length > 0) {
        alert(resp.data.errors.join(', '));
        console.error('getAllUserEvents | Errors:', resp.data.errors);
        return null;
      }
      if (debug) console.log('getAllUserEvents | resp', resp);
      return resp.data.data[0];
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const deleteUserInfo = async (params: {
    [key: string]: unknown;
  }): Promise<string | null> => {
    try {
      const resp = await User.delete(getToken(), params);
      if (resp.data.errors.length > 0) {
        alert(resp.data.errors.join(', '));
        console.error('deleteUserInfo | Errors:', resp.data.errors);
        return null;
      }
      if (debug) console.log('deleteUserInfo | resp', resp);
      return resp.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  const updateUserInfo = async (params: {
    [key: string]: unknown;
  }): Promise<AppInfoT | null> => {
    try {
      const resp = await User.update(getToken(), params);
      if (resp.data.errors.length > 0) {
        alert(resp.data.errors.join(', '));
        console.error('updateUserInfo | Errors:', resp.data.errors);
        return null;
      }
      if (debug) console.log('updateUserInfo | resp', resp);
      return resp.data.data;
    } catch (err) {
      handleError(err);
      return null;
    }
  };

  return {
    getUserInfo,
    getUserRepositories,
    getAllUsers,
    getUserByAdmin,
    getAllUserEvents,
    deleteUserInfo,
    updateUserInfo,
  };
};

export default useUserAppInfo;
