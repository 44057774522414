import { useContext } from 'react';
import { UserContext, UserEmpty } from '@as_core/contexts/UserContext';
import * as AWSCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import { jwtDecode } from 'jwt-decode';
import {APP_CONFIG} from "@app_config/app";

const debug = false;
const useCognito = () => {
  const { user, setUser } = useContext(UserContext);

  // return the access token
  const getToken = () => {
    if (debug) console.log('useCognito | getToken');
    return user.authSession.getAccessToken().getJwtToken();
  };

  // return the time (seconds) since authentication
  const getAuthRoles = (authSession:CognitoUserSession): Array<string> => {
    if (debug) console.log('useCognito | getAuthRoles');
    try {
      const token = jwtDecode(authSession.getAccessToken().getJwtToken());
      if (Object.hasOwn(token, 'cognito:groups')) {
        return token['cognito:groups'];
      }
    } catch (err) {
      if (debug) console.error('Error (getAnyRole)', err);
    }
    return [];
  };

  // return the time (seconds) since authentication
  const isTokenExpired = (authSession:CognitoUserSession) => {
    if (debug) console.log('useCognito | isTokenExpired', authSession);
    if (authSession === null) return true;
    try {
      const token = jwtDecode(authSession.getAccessToken().getJwtToken());
      // console.log('isTokenExpired:', token);
      const timestamp = new Date().getTime();
      return Math.floor(timestamp / 1000) > token['exp'];
    } catch (err) {
      console.error('Error', err);
    }
    return true;
  };

  // authenticate the user in cognito user pool
  const login = async (email: string, password: string): Promise<CognitoUserSession> => {
    const poolData = {
      UserPoolId: APP_CONFIG.cognitoUserPoolId,
      ClientId: APP_CONFIG.cognitoClientId,
    };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);

    const authenticationDetails = new AWSCognitoIdentity.AuthenticationDetails({
      Username: email,
      Password: password,
    });

    if (debug) console.log("--------Authenticate --- " + email);

    const cognitoUser = new AWSCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          const userId = result.getIdToken().payload;
          setUser((prev) => ({
            ...prev,
            isAuthenticated: true,
            isEmailVerified: true,
            authId: userId['cognito:username'],
            authEmail: email,
            authRoles: getAuthRoles(result),
            authSession: result,
          }));
          resolve(result);
        },
        onFailure: function (error) {
          console.error('login ERROR', error.message || error.stringify(error));
          if (error.message === 'User is not confirmed.') {
            setUser((prev) => ({
              ...prev,
              isAuthenticated: true,
              isEmailVerified: false,
            }));
          }
          reject(error.message);
        },
      });
    });
  };

  // method to create the user account in cognito with email/password
  const createAccount = async (email: string, password: string): Promise<ISignUpResult> => {
    const poolData = {
      UserPoolId: APP_CONFIG.cognitoUserPoolId,
      ClientId: APP_CONFIG.cognitoClientId,
    };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);

    const attributeList = [];

    const dataEmail = { Name: 'email', Value: email };

    const attributeEmail = new AWSCognitoIdentity.CognitoUserAttribute(
      dataEmail
    );

    attributeList.push(attributeEmail);

    return new Promise((resolve, reject) => {
      userPool.signUp(
        email,
        password,
        attributeList,
        null,
        function (error, result) {
          if (error) {
            reject(error.toString());
          } else {
            resolve(result);
          }
        }
      );
    });
  };

  // method to verify email
  const verifyEmail = async (email: string, verificationCode: string): Promise<string> => {
    const poolData = {
      UserPoolId: APP_CONFIG.cognitoUserPoolId,
      ClientId: APP_CONFIG.cognitoClientId,
    };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);

    if (debug) console.log("--------Authenticate --- " + email);

    const cognitoUser = new AWSCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(verificationCode, true, function(err, result) {
        if (err) {
          console.error('verifyEmail | error', JSON.stringify(err));
          if (err.code === 'ExpiredCodeException') {
            reject('ExpiredCodeException')
          }
        }
        console.log('call result: ' + result);
        resolve('Success');
      });
    });
  };

  // re-send the user a new code to use for verification
  const resendVerificationCode = async (email: string): Promise<string> => {
    const poolData = {
      UserPoolId: APP_CONFIG.cognitoUserPoolId,
      ClientId: APP_CONFIG.cognitoClientId,
    };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = new AWSCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode(function(err, result) {
        if (err) {
          console.error('resendConfirmationCode | error', JSON.stringify(err));
          reject(err.name);
        }
        if (debug) console.log('resendVerificationCode | result:', result);
        resolve('Success');
      });
    });
  };

  // send the user a code to allow a password reset
  const resetPassword = async (email: string) => {
    const poolData = {
      UserPoolId: APP_CONFIG.cognitoUserPoolId,
      ClientId: APP_CONFIG.cognitoClientId,
    };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = new AWSCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  };

  // method to set new password using emailed verification code
  const confirmPassword = (email: string, verificationCode: string, newPassword: string) => {
    const poolData = {
      UserPoolId: APP_CONFIG.cognitoUserPoolId,
      ClientId: APP_CONFIG.cognitoClientId,
    };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = new AWSCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve('success');
        },
      });
    });
  };

  const logout = (email: string) => {
    if (debug) console.log('useCognito | email', email);
    if (email !== null) {
      const poolData = {
        UserPoolId: APP_CONFIG.cognitoUserPoolId,
        ClientId: APP_CONFIG.cognitoClientId,
      };
      const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
      const cognitoUser = new AWSCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      });
      cognitoUser.signOut();
    }
    setUser(UserEmpty);
  };

  return {
    getToken,
    getAuthRoles,
    isTokenExpired,
    createAccount,
    verifyEmail,
    resendVerificationCode,
    login,
    logout,
    resetPassword,
    confirmPassword,
  };
};
export default useCognito;
