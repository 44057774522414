import { auth3RnDClient as authClient } from '@utils/api/base';
import useCognito from '@as_core/account/useCognito';
import useCache from '@as_core/hooks/useCache';

// generalized ApiResponse for Invitations API Calls
type ApiResponseT<T> = {
  data: {
    errors: string[],
    data: T
  }
  status: number,
  statusText: string
}

export type UserEmailT = {authId: string, authEmail: string};
export type UserEmailDictionaryT = {[key: string]: string};

// api call structure
const Users = {
  getEmailList: (token:string, list: string) => authClient(token).get(`/user/list?${list}`),
};

function listToDictionary (emailsList: UserEmailT[]) {
  const emailsDictionary = {};
  emailsList.forEach(email => {
    emailsDictionary[email.authId] = email.authEmail;
  })
  return emailsDictionary;
}

const debug = false;
// useInvitations hook to make the UI cleaner
const useUserEmails = () => {
  const {getToken} = useCognito();
  const emailCache = useCache<UserEmailT>();

  const getUserEmails = async (
    ids: string[],
  ): Promise<UserEmailDictionaryT> => {
    if (debug) console.log('current cache', emailCache.data);
    const uniqueIds = [...new Set(ids)];
    if (debug) console.log(ids, uniqueIds);
    // first check cache
    const emailsList = [];
    const missingIds = [];
    uniqueIds.forEach((id) => {
      const info = emailCache.get(id);
      if (info === null) {
        missingIds.push(id);
      } else {
        emailsList.push(info);
      }
    });
    if (debug) console.log('UserEmailList | getUserEmails | cache | emailsList', emailsList);
    if (missingIds.length === 0) return(listToDictionary(emailsList));

    let resp: ApiResponseT<UserEmailT[]>;
    try {
      if (debug) console.log('UserEmailList | getUserEmails | api | missingIds', missingIds);
      const idsList = uniqueIds.map((id) => (`ids=${id}`)).join('&');
      if (debug) console.log('UserEmailList | getUserEmails | api | idsList', idsList);
      resp = await Users.getEmailList(getToken(), idsList);
      if (debug) console.log('response:', resp);
      const newEmailsList = resp.data.data;
      missingIds.forEach((id) => {
        const info = newEmailsList.filter((e) => e.authId === id);
        if (info.length === 0) {
          emailCache.add(id, {authId: id, authEmail: ''});
        } else {
          emailCache.add(id, info[0]);
          emailsList.push(info[0]);
        }
      });

    } catch (err) {
      alert(err);
      if (debug) console.error('Invitation decline', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      if (debug) console.error('Invitation getByRepo', resp.data.errors);
    }
    return listToDictionary(emailsList);
  };

  return {
    getUserEmails,
  };
};

export default useUserEmails;
