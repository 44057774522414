import React from 'react';
import { Tooltip } from '@mui/material';
import { getValueByPath, truncateString } from '@utils/misc';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import MenuButton from '@as_core/elements/MenuButton';
import EditIcon from '@as_core/icons/edit.icon';
import ViewIcon from '@as_core/icons/view.icon';
import TextButton from "@as_core/controls/buttons/TextButton";
import CellHealthIcon from "@components/icons/cellhealth.icon";
import ZebrafishIcon from "@components/icons/zebrafish.icon";
import BiomimeticsIcon from "@components/icons/biomimetics.icon";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";
import {UserEmailDictionaryT} from "../hooks/useUserEmails";
import { RequestT } from '../hooks/useRequests';
import {VENDOR_MASK} from "../hooks/useVendors";

export type TableColT = {
  value?: string,
  label?: string,
  key?: string,
  type?: string,
  width?: number
}

export const showCodes = ['AS-SYS', 'AS-ZBE', 'AS-BMVK', 'AS-CQC-SST'];
const categoryIcons = {
  'AS-SYS': <CellHealthIcon size={48}/>,
  'AS-ZBE': <ZebrafishIcon size={48}/>,
  'AS-BMVK': <BiomimeticsIcon size={48}/>
}

export function getIcon(code: string) {
  return Object.hasOwn(categoryIcons, code) ? categoryIcons[code] : null;
}

const debug = false;
export const getRequestStatus = (
  request: RequestT,
  handleClick: (a:string, id:string) => void
) => {
  const status = request?.status;
  if (debug) console.log(request);
  if (status === 'Open') {
    const assigned_to = getValueByPath(request, 'assigned_to', '');
    // console.log('status: ', status, 'assigned_to', assigned_to);
    if (assigned_to === null || assigned_to === '') {
      return (
        <TextButton
          width={135}
          height={30}
          fontSize={12}
          margin={'2px'}
          label={'Assign Request'}
          onClick={() => handleClick('edit', request?.id)}
        />
      );
    } else {
      return "Waiting on Customer";
    }
  }
  return status;
};

export const getStringHover = (text: string, width: number) => {
  if (typeof(text) !== 'string') return '';
  const characters = Math.floor(width/4);
  if (text.length < characters) return text;
  return(
    <Tooltip title={text}>
      { truncateString(text, characters)}
    </Tooltip>
  )
}

export const getTitleInfo = (action: string, request: RequestT) => {
  return(
    <>
      {getValueByPath(request, 'name', '') + ' ' }
      <HoverInfoText text={getValueByPath(request, 'description', '')}/>
    </>
  )
}


// Return the display value for the column from the AssayRequestForms
export const getDisplayValue = (
  action: string,
  col: TableColT,
  request:RequestT,
  userEmails: UserEmailDictionaryT,
  handleClick: (a:string, id:string) => void
) => {
  if (col.type === 'status' ) {
    return getRequestStatus(request, handleClick);
  } else if (col.type === 'title') {
    return (getTitleInfo(action, request));
  } else if (col.type === 'email') {
    const user_id = getValueByPath(request, 'user_id');
    if (debug) console.log('user_id', user_id);
    if (userEmails !== undefined && Object.hasOwn(userEmails, user_id) && userEmails[user_id] !== '') {
      return userEmails[user_id];
    }
    return user_id;
  } else if (col.type === 'edit') {
    if (action === 'open') {
      if (getValueByPath(request, 'is_assigned', '') === '') {
        return (
          <MenuButton
            onClick={() => handleClick('edit', request?.id)}
            icon={<EditIcon/>}
            text={'Adjust Assignment'}
            tooltipPlacement={'bottom'}
          />
        )
      } else {
        return <></>
      }
    } else {
      return (
        <MenuButton
          onClick={() => handleClick('edit', request?.id)}
          icon={<EditIcon/>}
          text={'Update Assay Request'}
          tooltipPlacement={'bottom'}
        />
      )
    }
  } else if (col.type === 'hover_info') {
    const value = getValueByPath(request, col.value, '');
    return (
      <HoverInfoText text={value}/>
    )
  } else if (col.type === 'hover_text') {
    const value = getValueByPath(request, col.value, '');
    return (
      <HoverTextTruncate text={value}/>
    )
  } else if (col.type === 'vendor') {
    const value = getValueByPath(request, 'assigned_to', '');
    if (Object.hasOwn(VENDOR_MASK, value)) return VENDOR_MASK[value];
    return value;
  } else if (col.type === 'view') {
    return(
      <MenuButton
        onClick={() => handleClick('view', request?.id)}
        icon={<ViewIcon />}
        text={'View Full Assay Request'}
        tooltipPlacement={'bottom'}
      />
    )
  } else if (col.type === 'quantity') {
    let quantity = getValueByPath(request, 'quantity', 0);
    let unit_size = getValueByPath(request, 'unit_value', '');
    if (unit_size === null || quantity === null) return '';
    const parts = unit_size.split(' ');
    if (parts.length > 1) {
      quantity = quantity * Number(parts[0]);
      unit_size = parts[1];
      if (quantity > 1) unit_size += 's';
    }
    return quantity.toString().concat(' ', unit_size);
  } else {
    const value = getValueByPath(request, col.value, '');
    if (col.type === 'time') {
      const utcDate = new Date(value)
      return utcDate.toLocaleDateString() + ' ' + utcDate.toLocaleTimeString();
    } else if (col.type === 'date') {
      const utcDate = new Date(value)
      return utcDate.toLocaleDateString();
    } else if (col.type === 'number') {
      return value.toString();
    } else {
      return getStringHover(value, col.width);
    }
  }
}

export function getShowDate(stringDate: string) {
  if (!stringDate) return '';
  const utcDate = new Date(stringDate)
  return utcDate.toLocaleDateString() + ' ' + utcDate.toLocaleTimeString();
}

const titleMap: {[key: string]: string} = {
  'open': 'Upcoming Assay Requests',
  'shipped': 'Compounds Shipped by Customer',
  'received': 'Compounds Received at Lab',
  'running': 'Assays Running in Lab',
  'processing': 'Data Uploaded and Processing',
  'qc': 'Data in QC',
  'complete': 'Data Released to Customer'
}

export const getTitle = (action: string): string => {
  if (Object.hasOwn(titleMap, action)) return titleMap[action];
  return action;
}


export const getRequestStatusByAction = (action: string): string => {
  switch(action) {
    case 'open':
      return 'Open';
    case 'shipped':
      return 'Shipped';
    case 'active':
      return 'in_process';
    case 'closed':
      return 'closed';
  }
  return 'Title';
}