import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";
import DraggableModal from '@as_core/elements/DraggableModal';
import UpdateRequest from '../forms/UpdateRequest';
import BasicTable from '@as_core/tables/BasicTable';
import { ColDef } from '../utils/tableDefinitions';
import { getRequestsTableRows } from '../utils/renderValues';
import {UserEmailDictionaryT} from '../hooks/useUserEmails';
import useRequests, { RequestT, RequestUpdateT } from '../hooks/useRequests';
import useVendors from '../hooks/useVendors';
import RequestDetails from "./RequestDetails";

const noEditActions = ['processing']

const modalDimensions = {
  open: { height: 200, width: 500 },
  unassigned: { height: 180, width: 500 },
  active: { height: 250, width: 450 },
};


interface PropsI {
  action: string;
  requests: RequestT[];
  currentRequest: RequestT;
  setCurrentRequest: (request: RequestT) => void;
  userEmails: UserEmailDictionaryT;
}

const debug = false;
const RequestsTable = (props: PropsI) => {
  const { action, requests, currentRequest:selectedRequest, setCurrentRequest:setSelectedRequest, userEmails } = props;
  const queryClient = useQueryClient();
  const { updateRequest } = useRequests();
  const { getRequestType } = useVendors();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editRequest, setEditRequest] = useState<RequestT>(null);
  const [requestsRows, setRequestsRows] = useState<RequestT[]>([]);
  debug && console.log('partners/RequestsTable | {requests}:', requests);

  const cols = Object.hasOwn(ColDef, action) ? ColDef[action] : ColDef['open'];
  debug && console.log('RequestsTable: {cols}', cols);

  const mutation = useMutation({
    mutationFn: (param: {id: string, updates: RequestUpdateT}) => updateRequest(param.id, param.updates),
    onSuccess: (data) => {
      if (debug) console.log('user/RequestsTable | mutation | OnSuccess | data:', data);
      setSelectedRequest(data);
      queryClient.invalidateQueries({ queryKey: ['requests', 'user'] }).then();
      queryClient.invalidateQueries({ queryKey: ['requests', 'order', selectedRequest.id] }).then();
    }
  });

  const handleRequestUpdate = async (id: string, updates: RequestUpdateT) => {
    if (debug) console.log('user/RequestsTable | handleRequestUpdate {id, updates}:', id, updates);
    mutation.mutate({id: id, updates: updates})
    setSelectedRequest(null);
    setEditRequest(null);
  }

  const handleClick = (type: string, id: string) => {
    debug && console.log('handleClick ', type, id);
    const matched = requests.filter((r) => r.id === id);
    if (matched.length) {
      debug && console.log('handleEditClick: matched', matched);
      switch (type) {
        case 'edit':
          setEditRequest(matched[0]);
          setIsModalOpen(true);
          break;
        case 'view':
          setSelectedRequest(matched[0]);
          break;
        default:
          console.error('RequestsTable.handleclick | unknown type:', type);
      }
    }
  };


  useEffect(() => {
    if (requests !== undefined) {
      const updatedRequests = requests.map((r) => ({
        ...r, itemType: getRequestType(r), userEmail: Object.hasOwn(userEmails, r.user_id) ? userEmails[r.user_id] : r.user_id
      }))
      setRequestsRows(getRequestsTableRows(action, 'partners', updatedRequests, cols, handleClick));
    }
  }, [requests]);

  debug && console.log('RequestsTable: {requestsRows}', requestsRows);

  return (
    <>
      { requests.length ?
        <RequestTableContainer>
          { selectedRequest !== null ?
            <RequestDetails
              allowEdit={!noEditActions.includes(action)}
              request={selectedRequest}
              handleRequestUpdate={handleRequestUpdate}
              userEmails={userEmails}
              handleEdit={() => handleClick('edit', selectedRequest.id)}
              handleBack={()=>setSelectedRequest(null)}
            />
            :
            <>
              {/*
              <RequestsProcessMap
                requests={requests}
              />
              */}
              <BasicTable
                fields={cols}
                rows={requestsRows}
                border={false}
              />
            </>
          }
          <DraggableModal
            title={'Update Request Report'}
            height={
              Object.hasOwn(modalDimensions, action)
                ? modalDimensions[action].height
                : 600
            }
            width={
              Object.hasOwn(modalDimensions, action)
                ? modalDimensions[action].width
                : 600
            }
            isModalOpen={isModalOpen}
            onCloseClick={()=>setIsModalOpen(false)}
          >
            <UpdateRequest
              action={action}
              viewMode={'partners'}
              request={editRequest}
              handleRequestUpdate={handleRequestUpdate}
              closeDialog={() => setIsModalOpen(false)}
            />
          </DraggableModal>

        </RequestTableContainer>
        :
        <ErrorMessages messages={[
          <MessageItem key='emi_no_requests'>You do not have any {action} requests</MessageItem>
        ]}/>
      }
    </>
  );
};

export default RequestsTable;

const RequestTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

