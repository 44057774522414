import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface IconPropsI {
  size?: number;
}

const InsightsIcon = ({size = 24}:IconPropsI) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M2,22.8c0,0-.2,0-.3,0-.4-.2-.6-.6-.4-1l4.5-10.4c0-.2.3-.4.6-.4.2,0,.5,0,.7.2l3.5,3.7,2.7-5.2c.1-.3.4-.4.7-.4l4.9.6,2.5-5c.2-.4.6-.5,1-.3.4.2.5.6.3,1l-2.7,5.5c-.1.3-.5.4-.8.4l-4.9-.6-3,5.6c-.1.2-.3.4-.6.4-.2,0-.5,0-.6-.2l-3.4-3.6-4,9.4c-.1.3-.4.5-.7.5Z"/>
      <path fill={colorHighlight} d="M6.5,7.6v1.3c0,.2,0,.3-.1.4s-.2.1-.3.1-.2,0-.3-.1-.1-.2-.1-.4v-1.3h-1.1c-.2,0-.3,0-.4-.1s-.1-.2-.1-.3,0-.2.1-.3.2-.1.4-.1h1.1v-1.3c0-.2,0-.3.1-.4s.2-.1.3-.1.2,0,.3.1.1.2.1.4v1.3s1.1,0,1.1,0c.2,0,.3,0,.4.1s.1.2.1.3,0,.2-.1.3-.2.1-.4.1h-1.1Z"/>
      <path fill={colorHighlight} d="M17.7,6.6l-.9-.7c-.3-.2-.6-.2-.8,0l-.9.7c-.2.2-.6,0-.5-.3l.3-1.1c0-.3,0-.6-.3-.8l-.9-.7c-.3-.2-.1-.6.2-.6h1.1c.3,0,.6-.2.7-.5l.3-1.1c0-.3.5-.3.6,0l.4,1.1c.1.3.4.5.7.5h1.1c.3,0,.4.4.2.5l-.9.7c-.2.2-.3.5-.2.8l.4,1.1c.1.3-.2.5-.5.4Z"/>
    </svg>
  )
}

export default InsightsIcon;