import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface IconPropsI {
  size?: number;
}

const FulfillmentNewIcon = ({
    size = 24,
  }: IconPropsI) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M15,20c-2.4,0-4.5-.7-6.3-2h-4.2c-.6,0-1,.4-1,1s.4,1,1,1h15c.6,0,1-.4,1-1s0-.3-.1-.4c-1.6.9-3.4,1.4-5.4,1.4Z"/>
      <path fill={color} d="M4.6,12.5h0c-.6,0-1,.4-1,1s.4,1,1,1h1c-.4-.6-.7-1.3-.9-2Z"/>
      <path fill={color} d="M4.2,7.1c-.4.1-.7.5-.7.9s.2.7.5.9c0-.6,0-1.2.2-1.8Z"/>
      <path fill={color} d="M24,2.7c0-1.5-1.2-2.6-2.7-2.7,1,.7,1.9,1.6,2.7,2.7Z"/>
      <path fill={color} d="M22.5,17v4.2c0,.7-.6,1.2-1.2,1.2H2.7c-.7,0-1.2-.6-1.2-1.2V2.7c0-.7.6-1.2,1.2-1.2h4.2C7.5.9,8.1.4,8.7,0H2.7C1.2,0,0,1.2,0,2.7v18.5c0,1.5,1.2,2.7,2.7,2.7h18.5c1.5,0,2.7-1.2,2.7-2.7v-5.9c-.4.6-.9,1.2-1.5,1.7Z"/>
      <path fill={colorHighlight} d="M15,0C10,0,6,4,6,9s4,9,9,9,9-4,9-9S20,0,15,0ZM20.5,11h-3.5v3.5c0,1.1-.9,2-2,2s-2-.9-2-2v-3.5h-3.5c-1.1,0-2-.9-2-2s.9-2,2-2h3.5v-3.5c0-1.1.9-2,2-2s2,.9,2,2v3.5h3.5c1.1,0,2,.9,2,2s-.9,2-2,2Z"/>
    </svg>
  )
}

export default FulfillmentNewIcon;