import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@as_core/contexts/UserContext';
import { useParams } from 'react-router-dom';
import {useQuery} from "@tanstack/react-query";
import DataLoading from "@as_core/elements/DataLoading";
import PageContainer from '@as_core/pages/PageContainer';
import CenteredMessages, {  MessageItem } from '@as_core/elements/CenteredMessages';
import useVendors from "./hooks/useVendors";
import useRequests, {RequestT} from "./hooks/useRequests";
import useUserEmails from "./hooks/useUserEmails";
import { getTitle } from './common/utils';
import RequestPageHeader from "./common/PageHeader";
import RequestsTable from "./partners/RequestsTable";

const ADMIN_ROLES = ['aseda-admin', 'aseda-dev', 'aseda-partners'];

export function hasRequiredRole(userRoles: string[], requiredRoles: string[]) {
  return userRoles.some((r) => requiredRoles.includes(r));
}

type PropsT = {
  request?: RequestT;
}

const debug = false;
const PgRequestsPartners = (props:PropsT) => {
  const { request=null} = props;
  const { user, setUser } = useContext(UserContext);
  const { action } = useParams();
  const [userEmails, setUserEmails] = useState<{[key: string]: string }>({});
  const [emailRefreshKey, setEmailRefreshKey] = useState<number>(0);
  const [currentRequest, setCurrentRequest] = useState<RequestT>(request);
  const { getVendorAuthorizationByEmail } = useVendors();
  const { getRequestsByStatus } = useRequests();
  const { getUserEmails } = useUserEmails();

  const userAuthorizations = getVendorAuthorizationByEmail(user);

  // make sure correct subApp selected for left side menus
  if (user.subApp !== 'Requests') setUser((prev) => ({...prev, subApp: 'Requests'}));

  // load requests for the specific defined action
  const { isPending, error, data: requests } = useQuery({
    queryKey: ['requests', action],
    queryFn: () => getRequestsByStatus(action)
  });

  // get assay counts and updated when flagged by component
  useEffect(() => {
    setCurrentRequest(null);
  }, [action]);

  // get user email dictionary for requests -- this uses a cached function to reduce calls
  useEffect(() => {
    if (requests) {
      const ids = requests.map((r) => r.user_id);
      getUserEmails(ids).then((newEmailDict) => {
        if (debug) console.log('PgRequestsPartners | getUserEMails | newEmailDict:', newEmailDict);
        setUserEmails(prevState => ({...prevState, ...newEmailDict}));
        // issue with useEffect not re-rendering after emailDict updated so using key to push refresh
        setEmailRefreshKey(prevState => prevState + 1);
      });
    }
  }, [requests]);

  debug && console.log('PgRequestsPartners | action:', action, 'isPending', isPending, 'error', error, 'requests:', requests,
    'userEmails', userEmails);

  return (
    <PageContainer title={getTitle(action)}>
      <Container>
        <RequestPageHeader
          updateRequest={setCurrentRequest}
          userAuthorizations={userAuthorizations}
          showBack={currentRequest !== null}
        />
        {hasRequiredRole(user?.authRoles, ADMIN_ROLES) || userAuthorizations.length ? (
          <RequestsContainer>
            {
              isPending ? <DataLoading />
            :
            <RequestsTable
              key={`action_${emailRefreshKey}`}
              action={action}
              requests={requests}
              currentRequest={currentRequest}
              setCurrentRequest={setCurrentRequest}
              userEmails={userEmails}
            />
            }
          </RequestsContainer>
        ) : (
          <CenteredMessages
            messages={[
              <MessageItem key={'msg_no_authorization'} color={'accent'}>
                Not Authorized to Access this Tool/Functionality
              </MessageItem>,
              <MessageItem key={'msg_no_authorization_submit_report'} color={'accent'}>
                If you should be authorized, please submit a bug report.
              </MessageItem>,
            ]}
          />
        )}
      </Container>
    </PageContainer>
  );
};

export default PgRequestsPartners;

const Container = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  & ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
  }
  & ::-webkit-scrollbar-track {
      background: transparent;
  }
  & ::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.palette.accentPrimary};
      border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
      background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const RequestsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100vw - 50px);
  justify-content: center;
  overflow-x: auto;
`

