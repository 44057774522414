const cognitoClientId = process.env?.REACT_APP_COGNITO_CLIENT_ID ?
  process.env.REACT_APP_COGNITO_CLIENT_ID : '7drdcmut4up68pogttda1jrtq7';
const cognitoUserPoolId = process.env?.REACT_APP_COGNITO_USER_POOL_ID ?
  process.env.REACT_APP_COGNITO_USER_POOL_ID : 'eu-west-1_fH5LcXrK5'


export const APP_CONFIG = {
    name: '3RnD Partners',
    logo: '/img/3RnD_Logo.svg',
    system: '3rnd_partners',
    restrictedDomain: '', // add any restricted domains here ';' separated. asedasciences.com is always allowed
    cognitoClientId: cognitoClientId,
    cognitoUserPoolId: cognitoUserPoolId,
    loginInfo: [
        'An account is required to access 3RnD services and capability. ',
        'This same account allows access to other AsedaSciences applications and services such as SciScore. ',
        'Creating an account is a three step process: (1) Enter email/password (2) Verify email, and (3) Registering.'
    ],
    createAccountInfo: [
        'Creating an account is a three step process: (1) Enter email/password (2) Verify email, and (3) Registering.',
        'This is step (1) Enter your email and set a password.'
    ],
    verifyEmailInfo: [
        'Creating an account is a three step process: (1) Enter email/password (2) Verify email, and (3) Registering.',
        'This is step (2) to verify your email.',
        'Code was sent to your email upon step (1) from support@asedasciences.com. ' +
        'Check trash and spam folders, if necessary.'
    ],
    userRegisterInfo: [
        'Creating an account is a three step process: (1) Enter email/password (2) Verify email, and (3) Registering.',
        'This is step (3) to provide basic user account information.',
        'This is a one-time process for all AsedaSciences applications and this information can be updated later if needed.'
    ]
}